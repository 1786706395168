import {
  fieldVisible,
  IAutoComplete,
  IDate,
  ISelect,
  IText,
  Measurement,
  volumeUnit,
} from './utils'

const step2 = [
  {
    key: 'Material of construction',
    visible: fieldVisible,
    children: [
      new ISelect(
        'materialOfConstruction',
        'Material of construction',
        '',
        ['required'],
        [
          { value: 'frp', label: 'Frp' },
          { value: 'polyethylene', label: 'Polyethene' },
          { value: 'carbon_steel', label: 'Carbon steel' },
          { value: 'stainless_steel', label: 'Stainless steel' },
        ]
      ),
    ],
  },
  Measurement('usableVolume', 'Usable Volume', 'units', 'volume', ['required']),
  Measurement(
    'typicalDeliveryVolume',
    'Typical Delivery Volume',
    'typicalDeliveryVolumeUnit',
    'volume'
  ),
  Measurement(
    'otherOpeningsThatVent',
    'Other vent opening diameter',
    'otherOpeningsThatVentUnit',
    'diameter'
  ),
  Measurement(
    'fillPipeDiameter',
    'Fill Pipe Diameter',
    'fillPipeDiameterUnit',
    'diameter'
  ),
  Measurement(
    'tankVentDiameter',
    'Tank Vent Diameter',
    'tankVentDiameterUnit',
    'diameter'
  ),
  {
    key: 'Tank Lining',
    visible: fieldVisible,
    children: [new IText('tankLining', 'Tank Lining', '')],
  },
  {
    key: 'Fill Entry Point',
    visible: fieldVisible,
    children: [
      new ISelect(
        'fillEntryPoint',
        'Fill Entry Point',
        '',
        ['required'],
        [
          { value: 'mid', label: 'Mid' },
          { value: 'bottom', label: 'Bottom' },
          { value: 'top', label: 'Top' },
        ]
      ),
    ],
  },
  {
    key: 'Inspection',
    visible: fieldVisible,
    children: [new IDate(
      'lastInspectionDate',
      'Last Inspection Date',
      '',
      [`maxDate:${(new Date()).toISOString().split("T")[0]}`],
      (new Date()).toISOString().split("T")[0]
    )],
  },
]

export default step2
