import * as lodash from 'lodash'
const Match = {}
Match.required = function _(message) {
  const isEmptyArray = Array.isArray(this.value) && this.value.length === 0
  if (!this.value || isEmptyArray)
    throw new Error(message || `This field is required`)
}

Match.slug = function _(message) {
  const re = /\b[A-Z0-9]{4}$/
  if (!re.test(this.value))
    throw new Error(
      message ||
        `${this.key} should be 4 characters without spaces and special characters`
    )
}

Match.password = function _(message) {
  const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/
  if (!re.test(this.value))
    throw new Error(
      message ||
        `password length should be (8-30) characters and contain at least 1 number, and 1 special character`
    )
}

Match.number = function _(message) {
  if (this.key === 'chem class') this.key = 'product class'
  if (Number.isNaN(Number(this.value || '')))
    throw new Error(
      message || `${lodash.startCase(this.key)} should contain numbers only`
    )
}

Match.dashedNumber = function _(message) {
  const reg = /^(\d{3}[-]?){1,2}(\d{4})$/

  if (!reg.test(this.value))
    throw new Error(
      message ||
        `${lodash.startCase(this.key)} should be in the format xxx-xxx-xxxx`
    )
}

Match.email = function _(message) {
  if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.value))
    throw new Error(
      message ||
        `${lodash.startCase(this.key)} field is not a valid email address`
    )
}

Match.min = function _(min, message) {
  if (this.value.toString().length < min)
    throw new Error(
      message || `minimum length ${min} required for ${this.key} field`
    )
}

Match.max = function _(max, message) {
  if (this.key === 'chem class') this.key = 'product class'

  if (this.value.toString().length > max)
    throw new Error(
      message ||
        `Max ${max} characters allowed for ${lodash.startCase(this.key)}`
    )
}

Match.eq = function _(length, message) {
  if (
    this.value.toString().length !== Number(length) &&
    this.value.toString().length !== 0
  )
    throw new Error(
      message ||
        `${lodash.startCase(this.key)} should exactly be of length ${length}`
    )
}

Match.requiredDepends = function _(on, message) {
  if (this.data[on] && !this.value) {
    throw new Error(message || `this field cannot be empty`)
  }
}

Match.requiredOn = function _(on, value, message) {
  if (this.data[on] == value && !this.value) {
    throw new Error(message || `this field cannot be empty`)
  }
}

Match.requiredOnEmpty = function _(on, message) {
  const isEmptyArrayOn = Array.isArray(this.data[on]) && this.data[on].length === 0
  const isEmptyArray = Array.isArray(this.value) && this.value.length === 0

  if ((!this.data[on] || isEmptyArrayOn) && (!this.value || isEmptyArray)) {
    throw new Error(message || `This field is required`)
  }
}

Match.maxDate = function _(max, message) {
  if (this?.value && max) {
    if (new Date(this.value) > new Date(max)) {
      throw new Error(
        message ||
        `Max date allowed is ${max}`
      )
    }
  }
}

class Validation {
  static #validationSchema
  /**
   * Validates the object passed with the provided validationSchema
   * @param {object} data Object of fields to be validated
   * @param {object} validationSchema A validation schema that specifies array of validations to be performed
   * @returns {object} Errors for every field, if there is any
   * @example
   *
   * const fields = {
   *  email: 'test_test.com',
   *  password: '12345678abcde'
   * }
   *
   * const validationSchema = {
   *  email: ['required','email:This should be a valid email address'],
   *  password: ['number','min:3:Minimum 3 characters are required','max:8']
   * }
   * // a custom error message can be added after a colon(:)
   * //eg. ['required:This field cannot be empty']
   *
   * const errors = Validation.validate(fields, validationSchema);
   *
   * // errors would look like
   * {
   *    email: 'This should be a valid email address',
   *    password: 'password should be a number'
   * }
   *
   */

  static validate(data, validationSchema) {
    this.#validationSchema = validationSchema
    const keys = Object.keys(this.#validationSchema)
    const errors = {}
    keys.forEach(item => {
      const validation = this.#validationSchema[item]?.map(e =>
        e.trim().split(':')
      )
      try {
        validation.forEach(v => {
          const value = {
            key: item.split('_').join(' '),
            value: data[item],
            data,
          }
          Match[v[0]].call(value, ...v.slice(1))
        })
      } catch (error) {
        errors[item] = error.message
      }
    })
    return errors
  }

  /**
   * fields: [{data,validation},{data,validation}]
   */
  static getValidationStatus = fields => {
    const errorStatus = fields.map(e => false)
    for (const field in fields) {
      const { data, validation } = fields[field]
      const errors = Validation.validate(data, validation)
      if (Object.keys(errors).length) {
        console.log(errors)
      }
      if (Object.keys(errors).length > 0) {
        errorStatus[field] = true
      }
    }
    return errorStatus
  }
}

export default Validation
