import { useEffect, useState } from "react";
import Validation from "../validations/Validation";
import { toast } from 'react-toastify';

/**
 *
 * @param {object} init initial form field values
 * @param {object} validationSchema Validation Schema
 * @param {function} callback Submit handler function, receives values after submission
 */
const useForm = (init, validationSchema, callback) => {
  const [values, setValues] = useState(init)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const asyncCallback = async (values) => {
    try {
      await callback(values)
    }
    catch (e) {console.log(e)}

    setIsSubmitting(false)
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      asyncCallback(values)
    }
  }, [errors, isSubmitting])

  const handleOnChange = ({ name, value }) => {
    setValues({
      ...values,
      [name]: value,
    })
  }

  const focusOnError = (errors) => {
    if (Object.keys(errors).length === 0) return;

    let errNames = [];

    const getErrNames = (errors, path = '') => {
      const errKeys = Object.keys(errors)
      errKeys.forEach(key => {
        const error = errors[key]
        if (Array.isArray(error)) {
          error.forEach((err, index) => {
            if (err) getErrNames(err, `${path}${key}[${index}].`)
          })
        } else {
          if (typeof error === 'object' && error !== null) {
            const nestedKeys = Object.keys(error)
            getErrNames(error, `${path}${key}.`)
          } else {
            errNames.push(`${path}${key}`)
          }
        }
      })
    }

    getErrNames(errors)

    if (errNames.length > 0) {
      const selector = `[name="${errNames[0]}"]`
      let errorElement = document.querySelector(selector)

      if (errorElement) {
       try {
         const elementsByTagName = errorElement.getElementsByTagName('input');

         if (elementsByTagName?.length > 0) {
           errorElement = elementsByTagName[0];
         }

         if (errorElement) {
           errorElement.focus()
         }
       } catch (e) {}
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const err = Validation.validate(values, validationSchema)
    focusOnError(err)

    if (Object.keys(err).length !== 0) {
      toast.warning('Form validation failed');
    } else {
      setIsSubmitting(true)
    }

    setErrors(err)
  }

  const validate = (toastMessage = 'Form validation failed') => {
    const err = Validation.validate(values, validationSchema)
    focusOnError(err)

    if (Object.keys(err).length !== 0 && toastMessage) {
      toast.warning(toastMessage);
    }

    setErrors(err)
    return err
  }

  const resetForm = () => {
    setValues(init)
  }

  return {
    values,
    errors,
    setErrors,
    validate,
    focusOnError,
    setValues,
    isSubmitting,
    resetForm,
    handleSubmit,
    handleOnChange,
  }
}

export default useForm
